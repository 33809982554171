import axios from "axios";

// Define API URL
const API_URL = process.env.VUE_APP_API_URL;


// Define axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});


// Create API class for authentication
export class AuthService {
    static async isAuthenticated() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/auth/is-authenticated', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200;
        } catch (error) {
            return false;
        }
    }
    // Register a new user
    static async register(firstName: string, lastName: string, email: string, password: string) {
        const response = await axiosInstance.post('/v1/auth/register', { firstName: firstName, lastName: lastName, email: email, password: password});
        
        const status = await response.data.status;
        if (status === 'EMAIL_EXISTS') {
                return 2;
        }

        if (status === 'ERROR') {
            return 1;
        }

        return 0;
    }
    // Login a user
    static async login(email: string, password: string, recaptchaToken: string) {
        const response = await axiosInstance.post('/v1/auth/login', { email: email, password: password, recaptchaToken: recaptchaToken });

        if (response.status === 400) {
            throw new Error('Invalid credentials');
        }
        return await response.data;
    }
}