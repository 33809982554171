<template>
    <div class="jumbotron jumbotron-fluid">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-12 offset-xxl-2 offset-xl-2 pb-5">
                    <h1 class="d-none d-xl-block">Está a um passo de explorer o<br>potencial DriverWise!</h1>
                    <h2 class="d-block d-xl-none">Está a um passo de explorer o<br>potencial DriverWise!</h2>
                    <h3>Faça hoje o seu registo!</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container login mt-5 mb-5 pb-5 pt-5">
        <div class="row">
            <div class="col-12 text-center">
                <h2 style="font-size: 24px; font-weight: bolder;"><b>Iniciar Sessao</b></h2>
                <h5>Introduza o seu e-mail e a senha de acesso.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6 offset-xxl-4 offset-xl-4 offset-md-2">
                <form style="text-align: left;" method="post" :on-submit="login">
                    <div class="form-group">
                        <emailInput @onChange="onEmailInputUpdated" @keyup.enter="login"></emailInput>
                    </div>
                    <div class="form-group mt-2">
                        <PasswordInput @onChange="onPasswordInputUpdated" @keyup.enter="login"></PasswordInput>
                    </div>
                    <div class="form-group">
                        <PrimaryButton v-bind:extraClasses="'mt-1 mb-1'" v-bind:style="'width: 100%;'" v-bind:text="'Iniciar sessao'" v-bind:click="login" />
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xxl-4 col-xl-4 col-md-6 col-lg-6 offset-xxl-4 offset-xl-4 offset-md-6 offset-lg-6">
                <div class="row">
                    <div class="col-5"><hr></div>
                    <div class="col-2"><p class="text-center">ou</p></div>
                    <div class="col-5"><hr></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <GoogleLoginButton v-bind:click="login"></GoogleLoginButton>
                    </div>
                    <div class="col-12 mt-1">
                        <FacebookLoginButton v-bind:click="login"></FacebookLoginButton>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 text-center">
                        Não têm uma conta? <br>
                        <router-link to="/register">Registe-se agora!</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import PrimaryButton from '@/components/buttons/primaryButton.vue';
import FacebookLoginButton from '@/components/buttons/social/facebookLogin.vue';
import GoogleLoginButton from '@/components/buttons/social/googleLogin.vue';
import emailInput from '@/components/form/inputs/emailInput.vue';
import PasswordInput from '@/components/form/inputs/passwordInput.vue';
import { AuthService } from '@/services/authService';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'loginView',
  components: {
    PrimaryButton,
    GoogleLoginButton,
    FacebookLoginButton,
    emailInput,
    PasswordInput
  },
  setup() {
    // Use reCAPTCHA
    const { executeRecaptcha } = useReCaptcha() as unknown as { executeRecaptcha: () => Promise<string> };

    return {
        executeRecaptcha,
    };
  },
  data: function() {
    return {
        email: '',
        password: '',
        loggingIn: false
    }
  },
  mounted() {
    document.title = 'DriverWise - Iniciar Sessão';
  },
  methods: {
    onPasswordInputUpdated(password: string) {
      this.password = password;
    },
    onEmailInputUpdated(email: string) {
      this.email = email;
    },
    async login() {
      if (this.loggingIn) {
        return;
      }
      this.loggingIn = true;

      this.$swal.fire({
        title: 'A iniciar sessão...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });

      // Basic validation for email and password
      if (!this.email.includes('@') || !this.email.includes('.')) {
        this.loggingIn = false;
        this.$swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Por favor, preencha um email válido.',
        });
        return;
      }
      if (this.password === '') {
        this.loggingIn = false;
        this.$swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Por favor, preencha a senha.',
        });
        return;
      }

      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login_action')

        // Send login request to backend
        const response = await AuthService.login(this.email, this.password, token);

        if (response.status !== 'success' || !response.token) {
          this.$swal.fire({
            icon: 'error',
            title: 'Erro',
            html: 'Ocorreu um erro ao iniciar sessão.<br> Por favor, tente novamente.',
            confirmButtonColor: '#EF4E23',
          });
        } else {
          localStorage.setItem('token', response.token);
          this.$swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Sessão iniciada com sucesso!',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 2000,
          });
          setTimeout(() => {
            this.loggingIn = false;
            this.$router.push('/');
          }, 1000);
        }
      } catch (error) {
        this.loggingIn = false;
        this.$swal.fire({
          icon: 'error',
          title: 'Erro',
          html: 'Credenciais inválidas ou reCAPTCHA falhou.<br> Por favor, tente novamente.',
          confirmButtonColor: '#EF4E23',
        });
      }
    }
  },
});
</script>
<style scoped>
/* Align the container vertical content to the middle of the screen. */
.container.login {
    justify-content: center;
    align-items: center;
}
</style>