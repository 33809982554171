<template>
  <Transition name="fade">
    <div class="content-wrapper">
      <navbar></navbar>
      <router-view/>
    </div>
  </Transition>
  <FooterComponent></FooterComponent>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Navbar from './components/shared/navbar.vue';
import { AuthService } from './services/authService';
import FooterComponent from './components/shared/footer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    FooterComponent
  },
  async mounted() {
    if (await AuthService.isAuthenticated() == false) {
      document.querySelector('html')?.classList.add('bg-white');
      document.querySelector('body')?.classList.add('bg-white');
    }
  },
});
</script>
<style>
.container-fluid {
  padding: 0 !important;
}

html, body {
  background-color: #F8F8F8 !important;
}

.row {
  --bs-gutter-x: unset !important;
  --bs-gutter-y: unset !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.content-wrapper {
  min-height: calc(100vh - 330px);
  display: flex;
  flex-direction: column;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease; /* Duration of the fade animation */
}

.fade-enter, .fade-leave-to /* .fade-leave-active in Vue <2.1.8 */ {
  opacity: 0;
}

</style>