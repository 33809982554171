<template>
    <div v-if="showNavbar">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <router-link to="/" class="navbar-brand">
                <img src="/img/logobranco.png" alt="DriverWise">
            </router-link>
            <!-- Make sure to add the bars for the mobile menu -->
            <button class="navbar-toggler bg-white" @click="showNavbarMobile" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse navbar-pull-right" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item pt-1">
                        <a class="nav-link pt-3" href="#">
                            <i class="far fa-heart fa-2x"></i>
                        </a>
                    </li>
                    <li class="nav-item pt-1">
                        <a class="nav-link pt-3" href="#">
                            <i class="far fa-comments fa-2x"></i>
                        </a>
                    </li>
                    <li class="nav-item pt-1">
                        <a class="nav-link pt-3" href="#">
                            <i class="far fa-bell fa-2x"></i>
                        </a>
                    </li>
                    <li class="nav-item dropdown pt-1">
                        <div class="nav-link dropdown-toggle userDropdown" @click="toggleDropdown" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false" style="display: flex;">
                            <div class="gravatar">{{ name.charAt(0).toUpperCase() }}</div>
                        </div>
                        <div class="dropdown-menu show" v-if="dropdownVisible" id="user-menu" aria-labelledby="navbarDropdown">
                            <router-link to="/conta/viaturas" class="dropdown-item">Viaturas</router-link>
                            <a class="dropdown-item" href="#">Marcações</a>
                            <div class="dropdown-divider"></div>
                            <!--<a class="dropdown-item" href="#">Subscrição</a>-->
                            <router-link to="/conta" class="dropdown-item">Definições</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link to="/logout" class="dropdown-item">Sair</router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div v-else>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <router-link to="/" class="navbar-brand">
                <img src="/img/logobranco.png" alt="DriverWise" style="max-height: 50px;">
            </router-link>
            <div class="justify-content-center" style="margin-left: 31%">
                <router-link
                    to="/login"
                    class="btn"
                    :class="{ 'btn-active': isParticular }"
                >
                    Particular
                </router-link>
                <router-link
                    to="/shop/login"
                    class="btn"
                    :class="{ 'btn-active': isEmpresarial }"
                >
                    Empresarial
                </router-link>
            </div>
        </nav>
    </div>


    <div v-if="showMobileNavbar" class="collapsed navbar-collapse navbar-pull-right" id="navbarMobile">
        <ul class="navbar-nav">
            <li class="nav-item mobileNavItem" @click="showMobileNavbar = false">
                <a class="nav-link pt-3" href="#"  @click="showMobileNavbar = false">Favoritos</a>
            </li>
            <li class="nav-item mobileNavItem"  @click="showMobileNavbar = false">
                <a class="nav-link pt-3" href="#"  @click="showMobileNavbar = false">Mensagens</a>
            </li>
            <li class="nav-item mobileNavItem"  @click="showMobileNavbar = false">
                <a class="nav-link pt-3" href="#"  @click="showMobileNavbar = false">Notificações</a>
            </li>
            <li class="nav-item mobileNavItem"  @click="showMobileNavbar = false">
                <router-link to="/conta/viaturas" class="nav-link pt-3"  @click="showMobileNavbar = false">Viaturas</router-link>
            </li>
            <li class="nav-item mobileNavItem"  @click="showMobileNavbar = false">
                <a class="nav-link pt-3" href="#"  @click="showMobileNavbar = false">Marcações</a>
            </li>
            <li class="nav-item mobileNavItem"  @click="showMobileNavbar = false">
                <router-link to="/conta" class="nav-link pt-3"  @click="showMobileNavbar = false">Definições</router-link>
            </li>
            <li class="nav-item mobileNavItem"  @click="showMobileNavbar = false">
                <router-link to="/logout" class="nav-link pt-3"  @click="showMobileNavbar = false">Sair</router-link>
            </li>
        </ul>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavigationBar',
    computed: {
        isParticular() {
            return this.$route.name === 'login';
        },
        isEmpresarial() {
            return this.$route.name === 'shop.login';
        }
    },
    // Watch window.localStorage.token for changes
    watch: {
        '$route': 'checkTokenValidity',
    },
    data() {
        return {
            name: 'John Doe',
            showNavbar: false,
            dropdownVisible: false,
            gravatarUrl: '',
            showMobileNavbar: false
        };
    },
    mounted() {
        this.checkTokenValidity();

        // Watch for changes on the localStorage token, and hide the navbar if the token is removed
        window.addEventListener('click', this.handleClickOutside);
        window.addEventListener('storage', this.handleStorageEvent);
    },
    beforeUnmount() {
        window.removeEventListener('storage', this.handleStorageEvent);
    },
    methods: {
        toggleDropdown(event: MouseEvent) {
            event.stopPropagation();
            this.dropdownVisible = !this.dropdownVisible;
        },
        handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (!target.closest('#navbarDropdown')) {
                this.dropdownVisible = false;
            }
        },
        showNavbarMobile() {
            this.showMobileNavbar = !this.showMobileNavbar;
        },
        checkTokenValidity() {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = JSON.parse(atob(token.split('.')[1]));
                const expirationDate = new Date(decodedToken.exp * 1000); // convert to milliseconds
                const currentDate = new Date();

                // Check if token is not expired
                if (expirationDate > currentDate) {
                    this.name = decodedToken.name;
                    this.showNavbar = true;
                    this.generateGravatar();
                    setTimeout(() => {
                        document.querySelector('.grecaptcha-badge')?.classList.add('d-none');
                    }, 500);
                    return;
                } else {
                    this.showNavbar = false;
                    localStorage.removeItem('token');
                    return;
                }
            } else {
                this.showNavbar = false;
            }
            document.querySelector('.grecaptcha-badge')?.classList.remove('d-none');
        },
        handleStorageEvent(event: StorageEvent) {
            if (event.key === 'token') {
                this.checkTokenValidity();
            }
        },
        generateGravatar() {
            const firstLetter = this.name.charAt(0).toUpperCase();
            const gravatarUrl = `https://ui-avatars.com/api/?background=EF4E22&color=fff&name=${firstLetter}`;
            this.gravatarUrl = gravatarUrl;
        }
    },
});
</script>
<style scoped>
.navbar-pull-right .navbar-nav {
    margin-left: auto;
    justify-content: flex-end;
}

.navbar-light {
    padding: 10px;
    background-color: var(--primary-color) !important;
}

a.dropdown-item {
    cursor: pointer;
    color: #000 !important;
    transition: all 0.3s;
}

a.dropdown-item:hover {
    background-color: #eaeaea;
    color: var(--primary-color) !important;
}

.gravatar {
    background-color: white;
    color: #000000 !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 3px;
}

.gravatar:hover {
    background-color: #d3d3d3;
}

#user-menu {
    right: -10px;
    top: 50px;
}

.nav-link {
    cursor: pointer;
}

.nav-link::after {
    margin-top: 20px;
}

.nav-item {
    margin-left: 10px;
}

.nav-item > .nav-link > i[class^="far"] {
    color: rgb(255, 255, 255) !important;
    transition: all 0.3s;
}

.nav-item > .nav-link > i[class^="far"]:hover {
    color: #939393 !important;
}

.navbar-brand > img {
    cursor: pointer;
}

.userDropdown::after {
    color: white !important;
}

.mobileNavItem {
    transition: ease-in-out 0.3s;
}

.mobileNavItem > a {
    color: white !important;
}

.mobileNavItem > a:hover {
    color: #939393 !important;
}

#navbarMobile {
    display: block;
    position: absolute !important;
    top: 75px;
    left: 0px;
    background-color: var(--primary-color) !important;
    width: 100%;
    padding-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

#navbarMobile > .navbar-nav {
    display: flex;
    flex-direction: column;
}

#navbarMobile > .navbar-nav > .mobileNavItem {
    margin-left: 0px;
    padding: 10px;
    transition: ease-in-out 0.3s;
    padding-bottom: 15px;
}

#navbarMobile > .navbar-nav > .mobileNavItem > a {
    color: white !important;
}

#navbarMobile > .navbar-nav > .mobileNavItem > a:hover {
    color: white !important;
}

#navbarMobile > .navbar-nav > .mobileNavItem:hover {
    background-color: #c5421d;
    color: white !important;
}

.navbar {
    position: relative;
}

.navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
    z-index: 0;
    pointer-events: none;
}

.fa-2x {
    font-size: 1.3rem;
}

.btn {
    color: white !important;
    border: 1px solid white;
    background-color: transparent;
    margin: 0 10px;
    padding: 5px 20px;
    transition: all 0.3s;
}

/* Active state styling */
.btn-active {
    background-color: white;
    color: var(--primary-color) !important;
}

.btn:hover {
    color: var(--primary-color) !important;
    background-color: white !important;
}


.navbar-brand {
    padding-left: 20px;
}

.navbar-brand > img {
    height: 80px;
    width: auto;
}

.navbar-pull-right {
    justify-content: flex-end;
    padding-right: 20px;
    padding-top: 10px;
}
</style>