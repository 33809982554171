<template>
    <div class="jumbotron jumbotron-fluid">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-12 offset-xxl-2 offset-xl-2 pb-5">
                    <h1 class="d-none d-xl-block">A gestão inteligente do seu <br> automóvel com a DriverWise!</h1>
                    <h2 class="d-block d-xl-none">A gestão inteligente do seu <br> automóvel com a DriverWise!</h2>
                    <h3>Mais poupança, mais inteligente, mais simples, mais DriverWise!</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="col-12 col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <Search></Search>
        </div>
    </div>
    <div class="container-fluid mt-5">
        <CarInfo></CarInfo>
    </div>
    <div class="container-fluid mt-5">
        <div class="row ">
            <div class="col-12 col-xxl-8 offset-xxl-2">
                <img src="https://i.pinimg.com/originals/86/24/00/86240022e8bc8c661dbb2eeace972756.jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <Mostviewed></Mostviewed>
    </div>
    <div class="container-fluid">
        <Recentlyviewed></Recentlyviewed>
    </div>
  </template>

<script lang="ts">
import Search from '@/components/page/home/search.vue'
import CarInfo from '../components/page/home/carInfo.vue'
import { defineComponent } from 'vue';
import Mostviewed from './homepage/mostviewed.vue';
import Recentlyviewed from './homepage/recentlyviewed.vue';

export default defineComponent({
    components: {
        CarInfo,
        Search,
        Mostviewed,
        Recentlyviewed
    },
    name: 'HomePage',
    data() {
        return {
        name: 'DriverWise',
        dropdownVisible: false,
        };
    },
    mounted() {
        document.title = 'DriverWise - Dashboard';
        const token = localStorage.getItem('token');
        if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        this.name = decodedToken.name;
        }
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleDropdown(event: MouseEvent) {
            event.stopPropagation();
            this.dropdownVisible = !this.dropdownVisible;
        },
        handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (!target.closest('#navbarDropdown')) {
                this.dropdownVisible = false;
            }
        },
    },
});
</script>