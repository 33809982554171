<template>
    <div class="icon-container">
      <object :data="iconSrc" type="image/svg+xml" height="70"></object>
      <span v-if="status" :class="['badge', statusClass]">
        <span v-if="status === 'ok'"><i class="fas fa-check"></i></span>
        <span v-else-if="status === 'warning'"><i class="fas fa-exclamation"></i></span>
        <span v-else-if="status === 'danger'"><i class="fas fa-times"></i></span>
      </span>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, PropType } from 'vue';
  
  export default defineComponent({
    name: 'IconBadge',
    props: {
      iconSrc: {
        type: String,
        required: true,
      },
      status: {
        type: String as PropType<'ok' | 'warning' | 'danger'>,
        required: true,
      },
    },
    computed: {
      statusClass(): string {
        switch (this.status) {
          case 'ok':
            return 'ok';
          case 'warning':
            return 'warning';
          case 'danger':
            return 'danger';
          default:
            return '';
        }
      },
    },
  });
  </script>
  
  <style scoped>
  .icon-container {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
  }
  
  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .badge.ok {
    background-color: green;
  }
  
  .badge.warning {
    background-color: rgb(255,237,0);
    color: black; /* For better readability */
  }
  
  .badge.danger {
    background-color: red;
  }

  .fas {
    color: white;
}
  </style>
  