<template>
    <div class="container services-offered text-center">
        <h4>Serviços oferecidos</h4>
        <hr>
        <div class="row services-offered-results">
            <template v-for="(category, index) in data" :key="index">
                <!-- Start a new row for every 3rd item -->
                <div v-if="index % 3 === 0 && index !== 0" class="w-100"></div>
                
                <div class="col-4">
                    <div class="row">
                        <div class="col-10 offset-1">
                            <img :src="category.image" alt="category.name" class="img-fluid img-rounded">
                            <h6 class="text-center mt-2">{{ category.category }}</h6>
                            <ul>
                                <li v-for="(service, serviceIndex) in category.services" :key="serviceIndex" @click="openServiceModel(service)">
                                    {{ service.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="data.length === 0" class="col-12 text-center pt-5 pb-5">
                <h6 style="color: black;">
                    Este establecimento ainda não adicionou serviços.<br>
                    Por favor, volte mais tarde.
                </h6>
            </div>
        </div>
    </div>
    <div v-for="(category, index) in data" :key="index">
        <div v-for="(service, serviceIndex) in category.services" :key="serviceIndex">
            <transition name="fade" v-if="showServiceModel === service.id">
                <serviceOverview :service="service" :shop="shop" @closed="closeServiceModel"></serviceOverview>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import serviceOverview from './serviceOverview.vue';

export default defineComponent({
    name: 'servicesAvailable',
    components: {
        serviceOverview
    },
    data: () => {
        return {
            showServiceModel: 0
        }
    },
    props: {
        data: {
            type: Array,
            required: true
        },
        shop: {
            type: Object,
            required: true
        }
    },
    methods: {
        openServiceModel(service: any) {
            this.showServiceModel = service.id;
        },
        closeServiceModel() {
            this.showServiceModel = -1;
        }
    }
});
</script>
<style scoped>
.services-offered {
    padding: 20px;
    background-color: white !important;
    border-radius: 10px;
    margin-top: 20px;
    overflow-y: scroll;
    border: 1px solid #d7d7d7;
}

.services-offered-results {
    margin-top: 20px;
    max-height: 600px;
    overflow-y: scroll;
}

.img-rounded {
    border-radius: 15px;
    width: 100%;
    height: auto;
}

ul > li {
    list-style-type: none;
    padding-left: unset;
    margin-left: unset;
    border-bottom: 1px solid #d7d7d7;
    transition: ease-in-out 0.3s;
    padding: 5px;
    border-radius: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

ul > li:hover {
    background-color: #f7f7f7;
    cursor: pointer;
    color: #EF4E22
}

ul {
    padding-left: unset;
}

.text-center {
    text-align: center;
}
</style>