<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-1 offset-xxl-1 pt-5 text-right" style="padding-right: unset;text-align: right;" v-if="vehicles.length > 1">
                <i class="fas fa-chevron-left pt-5 fa-6x" @click="switchVehicle"></i>
            </div>
            <div class="col-10 col-xxl-8" :class="{'offset-2': vehicles.length <= 1}">
                <div class="card" :style="{ filter: isLoading ? 'blur(7px)' : 'none' }">
                    <div class="row">
                        <div class="col-12 col-xl-4 col-xxl-4 col-lg-5 col-sm-12 col-xs-12 col-xss-12" style="padding: unset;">
                            <img :src="selectedCar.photo || '/img/car-placeholder.png'" alt="Car" class="carImg" />
                        </div>
                        <div class="col-12 col-xl-4 col-xxl-4 col-lg-3 col-sm-12 pt-3 mt-4 carData" style="padding-left: 30px;">
                            <h4><b>{{ selectedCar.brand.name }} {{ selectedCar.model }}</b></h4>
                            <h5 class="info-text mt-3">Matrícula: {{ selectedCar.licensePlate }}</h5>
                            <h5 class="info-text mt-3">VIN: {{ selectedCar.vinNumber }}</h5>
                            <h5 class="info-text mt-3">KMS: {{ selectedCar.mileage }}</h5>
                            <h5 class="info-text mt-3">
                                <span class="text-danger" style="font-size: 14px;" v-if="vehicleNeedsAttention">
                                    <i class="fas fa-exclamation-triangle" style="color: #FF0000;"></i>
                                    Necessita de atenção
                                    <i class="fas fa-exclamation-triangle" style="color: #FF0000;"></i>
                                </span>
                            </h5>
                        </div>
                        <div class="col-12 col-lg-4 col-xxl-4 col-xl-4 col-sm-12 carStatus" style="padding-left: 10px; padding-right: 10px; padding-top: 50px;">
                            <div class="row">
                                <div class="col-3 text-center icon-status">
                                    <BadgeComponent iconSrc="/img/icons/icon-iuc.svg" :status="vehicleTaxStatus"></BadgeComponent>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <BadgeComponent iconSrc="/img/icons/icon_seguro.svg" :status="vehicleInsuranceStatus"></BadgeComponent>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <BadgeComponent iconSrc="/img/icons/icon_revisao.svg" :status="vehicleMaintenanceStatus"></BadgeComponent>
                                </div>
                            </div>
                            <div class="row pt-3 mb-5">
                                <div class="col-3 text-center icon-status">
                                    <BadgeComponent iconSrc="/img/icons/ico_inspection.svg" :status="vehicleInspectionStatus"></BadgeComponent>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <BadgeComponent iconSrc="/img/icons/icon_pneutras.svg" :status="vehicleRearTiresStatus"></BadgeComponent>
                                </div>
                                <div class="col-3 offset-1 text-center icon-status">
                                    <BadgeComponent iconSrc="/img/icons/icon_pneusfrente.svg" :status="vehicleFrontTiresStatus"></BadgeComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1 pt-5" v-if="vehicles.length > 1" style="margin-left: -10px;">
                <i class="fas fa-chevron-right pt-5 fa-6x" @click="switchVehicle"></i>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Vehicle } from '@/dtos/userService/vehicleInterface';
import { UserService } from '@/services/userService';
import { defineComponent } from 'vue';
import { VehiclesService } from '@/services/vehiclesService';
import BadgeComponent from './badgeComponent.vue';

export default defineComponent({
    name: 'CarInfo',
    components: {
        BadgeComponent
    },
    computed: {
        vehicleNeedsAttention(): boolean {
            return this.vehicleTaxStatus === 'danger' ||
                this.vehicleInsuranceStatus === 'danger' ||
                this.vehicleMaintenanceStatus === 'danger' ||
                this.vehicleInspectionStatus === 'danger' ||
                this.vehicleFrontTiresStatus === 'danger' ||
                this.vehicleRearTiresStatus === 'danger';
        },
        vehicleTaxStatus(): 'ok' | 'warning' | 'danger' {
            if (!this.selectedCar.registrationDate) {
                return 'ok';
            }
            const currentDate = new Date();
            const taxDate = new Date(this.selectedCar.registrationDate);

            // Get month from the tax date
            const taxMonth = taxDate.getMonth();

            // If taxMonth is more than 2 away from the current month, it means the tax is not due yet
            if (taxMonth > currentDate.getMonth() + 2) {
                return 'ok';
            }

            // If taxMonth is more than 1 away from the current month, it means the tax is due soon
            if (taxMonth >= currentDate.getMonth() + 1) {
                return 'warning';
            }

            // If taxMonth is between 0 and 1 away from the current month, it means the tax is overdue
            if (taxMonth >= currentDate.getMonth()) {
                return 'danger';
            }

            return 'ok';
        },
        vehicleInsuranceStatus(): 'ok' | 'warning' | 'danger' {
            if (!this.selectedCar.insuranceStartDate || !this.selectedCar.insuranceRecurrenceMethod) {
                return 'ok';
            }

            const insuranceRecurrence = Number(this.selectedCar.insuranceRecurrenceMethod);
            const insuranceStartDate = new Date(this.selectedCar.insuranceStartDate);
            const currentDate = new Date();
            
            let nextInsuranceDueDate = new Date(insuranceStartDate); // Make a copy of the start date
            while (nextInsuranceDueDate <= currentDate) {
                nextInsuranceDueDate.setMonth(nextInsuranceDueDate.getMonth() + insuranceRecurrence);
            }

            const threeMonthsFromNow = new Date();
            threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

            const twoMonthsFromNow = new Date();
            twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);

            const oneMonthFromNow = new Date();
            oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

            if (nextInsuranceDueDate >= threeMonthsFromNow) {
                return 'ok';
            }

            if (nextInsuranceDueDate >= twoMonthsFromNow) {
                return 'warning';
            }

            if (nextInsuranceDueDate >= oneMonthFromNow) {
                return 'warning';
            }

            if (nextInsuranceDueDate >= currentDate) {
                return 'danger';
            }

            return 'ok';
        },
        vehicleMaintenanceStatus(): 'ok' | 'danger' {
            if (
                this.selectedCar.oilChangeFlag ||
                this.selectedCar.brakePadsFlag ||
                this.selectedCar.brakeDiscsFlag ||
                this.selectedCar.timingBeltFlag ||
                this.selectedCar.waterPumpFlag ||
                this.selectedCar.sparkPlugsFlag ||
                this.selectedCar.airFilterFlag ||
                this.selectedCar.cabinFilterFlag ||
                this.selectedCar.fuelFilterFlag
            ) {
                return 'danger';
            }

            return 'ok';
        },
        vehicleInspectionStatus(): 'ok' | 'warning' | 'danger' {
            if (!this.selectedCar.inspectionDate || !this.selectedCar.manufacturerDate) {
                return 'ok';
            }

            const manufacturerDate = new Date(this.selectedCar.manufacturerDate);
            const inspectionDate = new Date(this.selectedCar.inspectionDate);
            const currentDate = new Date();

            const vehicleAgeInYears = (currentDate.getTime() - manufacturerDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25);

            let nextInspectionDueDate = new Date(inspectionDate);
            
            if (vehicleAgeInYears < 4) {
                nextInspectionDueDate.setFullYear(inspectionDate.getFullYear() + 4);
            } else if (vehicleAgeInYears < 8) {
                while (nextInspectionDueDate <= currentDate) {
                    nextInspectionDueDate.setFullYear(nextInspectionDueDate.getFullYear() + 2);
                }
            } else {
                while (nextInspectionDueDate <= currentDate) {
                    nextInspectionDueDate.setFullYear(nextInspectionDueDate.getFullYear() + 1);
                }
            }

            const threeMonthsFromNow = new Date();
            threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

            const twoMonthsFromNow = new Date();
            twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);

            console.log('Next inspection due date:', nextInspectionDueDate);
            console.log('Current date:', currentDate);

            if (nextInspectionDueDate >= threeMonthsFromNow) {
                return 'ok';
            }

            if (nextInspectionDueDate >= twoMonthsFromNow) {
                return 'warning';
            }

            if (nextInspectionDueDate >= currentDate) {
                return 'danger';
            }

            return 'ok';
        },
        vehicleFrontTiresStatus(): 'ok' | 'warning' | 'danger' {
            if (this.selectedCar.frontTiresFlag) {
                return 'danger';
            }

            return 'ok';
        },
        vehicleRearTiresStatus(): 'ok' | 'warning' | 'danger' {
            if (this.selectedCar.rearTiresFlag) {
                return 'danger';
            }

            return 'ok';
        }
    },
    mounted() {
        const userService = new UserService();

        UserService.obtainVehicles().then((response) => {
            if (!response) {
                return;
            }

            this.vehicles = response;
        });

        userService.obtainDefaultVehicle().then((response) => {
            if (!response) {
                this.$swal.fire({
                    // Create a quick alert to inform the user that he needs to add a vehicle
                    html: 'Parece que ainda não adicionou nenhuma viatura. Por favor, adicione uma viatura para continuar.',
                    icon: 'info',
                    confirmButtonText: 'Adicionar viatura',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    // Style the confirm button to match the primary color
                    confirmButtonColor: '#EA7C69',
                }).then(() => {
                    this.$router.push({ name: 'profile.vehicles' });
                });
                return;
            }

            this.selectedCar = response;

            // Convert dates to a more readable format like DD-MM-YYYY
            this.isLoading = false;
        });
    },
    data() {
        return {
            vehicles: [] as Vehicle[],
            selectedCar: {
                id: 0,
                brand: { id: 0, name: 'Toyota' },
                model: 'Yaris',
                subModel: '1.0',
                licensePlate: '00-00-00',
                registrationDate: '01-01-2000',
                manufacturerDate: '01-01-2000',
                insuranceStartDate: '01-01-2000',
                inspectionDate: '01-01-2000',
                vinNumber: '00000000000000000',
                mileage: 0,
            } as unknown as Vehicle,
            isLoading: true
        }
    },
    methods: {
        switchVehicle() {
            this.isLoading = true;
            setTimeout(() => {
                const currentIndex = this.vehicles.findIndex((vehicle) => vehicle.id === this.selectedCar.id);
                const nextIndex = currentIndex + 1;

                if (nextIndex >= this.vehicles.length) {
                    this.selectedCar = this.vehicles[0];
                    VehiclesService.setDefaultVehicle(this.selectedCar.id).then(() => {
                        this.isLoading = false;
                    });
                    return;
                }

                this.selectedCar = this.vehicles[nextIndex];
                VehiclesService.setDefaultVehicle(this.selectedCar.id).then(() => {
                    this.isLoading = false;
                });
            }, 100)
        }
    }
})
</script>
<style scoped>
.card {
    opacity: 0.9;
    transition: all 0.3s;
    border-radius: 10px;
}

.card .card-header {
    border-bottom: unset;
    background-color: unset;
}

.card .card-header .title {
    color: #EA7C69;
    margin-left: 10px;
}

.card .card-header .license-plate {
    color: #EA7C69;
    text-align: right;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
}

.fa-chevron-right, .fa-chevron-left {
    color: #EA7C69;
    cursor: pointer;
    transition: all 0.3s;
}

.fa-chevron-right:hover, .fa-chevron-left:hover {
    color: #EF4E22;
}

.justify-text-right {
    text-align: right;
}

.card {
    border: 1px solid #dadada;
    border-left: unset;
    border-right: unset;
    border-top: unset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.img-fluid {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
}

.icon-status {
    color: white;
    text-align: center;
}

.icon-status img {
    width: 100% !important;
}

.icon-status.ok {
    background-color: green;
}

.icon-status.warning {
    background-color: orange;
}

.icon-status.error {
    background-color: #FF0000;
}

/** make sure .carImg maintains full height of div. */
.carImg {
    height: 285px;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media screen and (max-width: 991px) {
    .carImg {
        max-height: unset;
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .info-text {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 992px) {
    .carImg {
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}
</style>